window.addEventListener('DOMContentLoaded', () => {
  let bannerContainer = document.querySelector('.sharpen-smart-banner');
  if (bannerContainer) {
    let banner = bannerContainer.querySelector('.smart-banner');
    bannerContainer.style.zIndex = banner.dataset.zIndex;
    let smartBannerClosedTime = localStorage.getItem('smartBannerClosedTime');
    let closedTime = smartBannerClosedTime ? new Date(smartBannerClosedTime) : new Date(0);
    let now = new Date();
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (now > closedTime && isMobile) {
      banner.classList.remove('d-none');
      let closeBanner = banner.querySelector('.smart-banner-close');
      closeBanner.addEventListener('click', () => {
        let date = new Date();
        date.setMinutes(date.getMinutes() + parseInt(banner.dataset.closedTime));
        localStorage.setItem('smartBannerClosedTime', date);
        banner.classList.add('d-none');
      });
    }
  }
});
